<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('models', 'read')" type="is-secondary export" :loading="exporting" size="is-small" rounded outlined @click="exportModels($event)">
				<svg-icon class="icon is-small" icon="export"></svg-icon>
				<span>Exportar</span>
			</b-button>
			<b-button v-if="permissionEnabled('models', 'create')" type="is-primary create" rounded @click="createModel($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>Agregar</span>
			</b-button>
		</Header>
		<section class="filter">
			<div class="filter__wrapper">
				<b-field>
					<b-input placeholder="Buscar" type="search" icon-right="magnify" @input="findByName" v-model="model.name"></b-input>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="!loading && !errored && models.length <= 0" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline" tag="div">
			<div v-for="d in models" :key="d.id" class="column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block secondary" @click.self="updateModel(d.id)">
					<div class="block__content" @click="updateModel(d.id)">
						<h3 class="block__name is-capitalized" translate="no">{{ d.vehicle }}</h3>
						<h5 class="block__model is-capitalized" translate="no" :title="d.version">{{ d.version }}</h5>
						<p class="block__address block__porcentage is-capitalized">
							<!-- <strong>Precio:</strong>  -->
							<!-- <input class="input-text" v-money="priceMask" :value="parseFloat(d.base_price).toFixed(1)"  readonly />  -->
							<strong>Porcentaje:</strong> {{ d.percent }}%
						</p>
					</div>
					<Trigger :id="d.id" :role="d.vehicle" :name="d.vehicle" :active="1" />
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Role'
import Trigger from '@/components/triggers/Model'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Modal from '@/components/modals/Models'
import { VMoney } from 'v-money'
import { create, update } from '@/mixins/modal'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import { mapGetters } from 'vuex'
import '@/mixins/date'
import '@/mixins/generic'
import actionUrl from '@/mixins/actionurl'

export default {
	components: {
		Layout,
		Header,
		Placeholder,
		Error,
		Results,
		Trigger,
		'svg-icon': Icon
	},
	directives: {
		money: VMoney
	},
	mixins: [
		actionUrl
	],
	data() {
		return {
			exporting: false,
			errored: false,
			loading: true,
			pagination: 15,
			model: {
				name: ''
			},
			models: [],
			priceMask: {
				decimal: ',',
				thousands: '.',
				prefix: '$ ',
				precision: 1
			}
		}
	},
	methods: {
		getUrlParams() {
			const url = location.href.split('/')
			const params = url[4]
			const type = url.find(el => el === params)

			switch (type) {
				case 'create':
					this.createModel()
					break
				case 'edit':
					this.updateModel(url[5])
			}
		},
		createModel() {
			if (this.permissionEnabled('models', 'create')) {
				create('models', Modal, 'New')
			}
		},
		updateModel(id) {
			if (this.permissionEnabled('models', 'edit')) {
				update('models', id, Modal, 'Edit')
			}
		},
		async getAllModels() {
			try {
				const response = await Api.get('model/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.models = data
					this.loading = false
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async findByName() {
			try {
				const empty = /^\s*$/
				if (!empty.test(this.model.name)) {
					const response = await Api.post('model/findByName', {
						name: this.model.name
					})
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.models = data
					}
				} else {
					await this.getAllModels()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async exportModels() {
			this.exporting = true
			try {
				const response = await Api.post('model/export')
				const { status } = response
				if (status === 422) {
					errorToast('Ocurrió un <strong>error</strong> al exportar los criterios.')
				} else {
					const { message } = response.data
					successToast('Los modelos se exportaron <strong>con éxito</strong>.')
					setTimeout(() => {
						this.exporting = false
						const node = document.createElement('a')
						node.href = message
						node.click()
					}, 2000)
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.exporting = false
			}
		}
	},
	mounted() {
		this.getAllModels()
		this.getUrlParams()
		eventHub.$on('reload-models', () => {
			this.getAllModels()
		})
		eventHub.$on('edit-modal-model', obj => {
			update('models', obj.id, Modal, 'Edit')
		})
		eventHub.$on('delete-model', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: '¡Atención!',
				message: '<span>¿Realmente desea <strong>eliminar</strong> este modelo?</span> <small>Esta acción no se puede deshacer y tiene un impacto diferente en el funcionamiento del sistema.</small>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: 'No',
				confirmText: 'Sí',
				onConfirm: async () => {
					try {
						const response = await Api.delete(`model/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							successToast('¡El modelo se <strong>eliminó</strong> correctamente!')
							eventHub.$emit('reload-models')
						}
					} catch (e) {
						console.log(e)
					}
				}
			})
		})
	},
	destroyed() {
		eventHub.$off('reload-models')
		eventHub.$off('edit-modal-model')
		eventHub.$off('delete-model')
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>
